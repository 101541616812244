import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllQuizAPI,
  addQuizAPI,
  deleteQuizAPI,
  updateQuizAPI,
  updateQuizPublishAPI,
  getAllQuizByUserAPI,
} from "../api/quizAPI";

const initialState = {
  quizModal: 0,
  getloading: false,
  quiz: [],
  addnUploading: null,
  deleteLoading: false,
  updateLoading: false,
  updatePublishLoading: false,
};

export const getQuiz = createAsyncThunk("quiz/getQuiz", async () => {
  const res = await getAllQuizAPI();
  return res.data;
});

export const getQuizByUser = createAsyncThunk(
  "quiz/getQuizByUser",
  async (payload) => {
    const res = await getAllQuizByUserAPI(payload);
    return res.data;
  }
);

export const addQuiz = createAsyncThunk("quiz/addQuiz", async (payload) => {
  const res = await addQuizAPI(payload);
  return res.data;
});

export const deleteQuiz = createAsyncThunk(
  "quiz/deleteQuiz",
  async (payload) => {
    const res = await deleteQuizAPI(payload);
    return res.data;
  }
);

export const updateQuiz = createAsyncThunk(
  "quiz/updateQuiz",
  async (payload) => {
    const res = await updateQuizAPI(payload);
    return res.data;
  }
);

export const updateQuizPublish = createAsyncThunk(
  "quiz/updateQuizPublish",
  async (payload) => {
    const res = await updateQuizPublishAPI(payload);
    return res.data;
  }
);

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    setQuizModalEdit: (state) => {
      state.quizModal = 2;
    },
    setQuizModalAdd: (state) => {
      state.quizModal = 1;
    },
    setQuizModalClose: (state) => {
      state.quizModal = 0;
    },
  },
  extraReducers: (builder) => {
    //get quiz
    builder.addCase(getQuiz.pending, (state) => {
      state.getloading = true;
    });
    builder.addCase(getQuiz.fulfilled, (state, action) => {
      state.getloading = false;
      state.quiz = action?.payload?.response;
    });
    builder.addCase(getQuiz.rejected, (state) => {
      state.getloading = false;
    });

    //get quiz by User
    builder.addCase(getQuizByUser.pending, (state) => {
      state.getloading = true;
    });
    builder.addCase(getQuizByUser.fulfilled, (state, action) => {
      state.getloading = false;
      state.quiz = action?.payload?.response;
    });
    builder.addCase(getQuizByUser.rejected, (state) => {
      state.getloading = false;
    });

    //add quiz
    builder.addCase(addQuiz.pending, (state) => {
      state.addloading = true;
    });
    builder.addCase(addQuiz.fulfilled, (state, action) => {
      state.addloading = false;

      if (state.quiz?.length > 0) {
        state.quiz.push(action?.payload?.response);
      } else {
        state.quiz = [action?.payload?.response];
      }
      state.quizModal = 0;
    });
    builder.addCase(addQuiz.rejected, (state) => {
      state.addloading = null;
    });

    //update quiz
    builder.addCase(updateQuiz.pending, (state) => {
      state.updateLoading = true;
    });
    builder.addCase(updateQuiz.fulfilled, (state, action) => {
      state.updateLoading = false;
      state.quiz = state.quiz.map((value) => {
        return value.quizid === action?.payload?.response?.quizid
          ? action?.payload?.response
          : value;
      });
      state.quizModal = 0;
    });
    builder.addCase(updateQuiz.rejected, (state) => {
      state.updateLoading = false;
    });

    //update publish quiz
    builder.addCase(updateQuizPublish.pending, (state) => {
      state.updatePublishLoading = false;
    });
    builder.addCase(updateQuizPublish.fulfilled, (state, action) => {
      state.updatePublishLoading = true;

      const index = state.quiz.findIndex((value) => {
        return value?.quizid === action?.payload?.response?.quizid;
      });
      const temp = state.quiz[index];

      const data = { ...temp, publish: action?.payload?.response?.publish };

      state.quiz[index] = data;
    });
    builder.addCase(updateQuizPublish.rejected, (state) => {
      state.updatePublishLoading = false;
    });

    //delete quiz
    builder.addCase(deleteQuiz.pending, (state) => {
      state.deleteLoading = true;
    });
    builder.addCase(deleteQuiz.fulfilled, (state, action) => {
      state.deleteLoading = false;

      const index = state.quiz.findIndex((value) => {
        return value.quizid === action?.payload?.response.quizid;
      });

      state.quiz.splice(index, 1);
    });
    builder.addCase(deleteQuiz.rejected, (state) => {
      state.deleteLoading = false;
    });
  },
});

export const { setQuizModalAdd, setQuizModalEdit, setQuizModalClose } =
  quizSlice.actions;
export default quizSlice.reducer;
