import API from "./API";

export const addAnswerAPI = (payload) => {
  return API.post("/answer", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateAnswerAPI = (payload) => {
  return API.put("/answer", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
