import axios from "axios";
import store from "../stores/store";
import { showNotification } from "../slices/notificationSlice";

const handleSubdomain = () => {
  let subdomain = window.location.hostname.split(".")[0];

  const hideTenantId = ["/admin/profile"];

  if (subdomain === "www" || subdomain === "localhost") {
    return {
      "Content-Type": "application/json",
    };
  } else {
    return {
      "Content-Type": "application/json",
      ...(!hideTenantId.includes(window.location.pathname) && {
        "X-Tenant-Id": subdomain,
      }),
    };
  }
};

let API = axios.create({
  baseURL: process.env.REACT_APP_BASEURL_VARIABLE,
  headers: handleSubdomain(),
});

API.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    if (
      (response.data.success &&
        window.location.pathname.split("/").indexOf("auth") === -1 &&
        response.config.method === "post") ||
      response.config.method === "put" ||
      response.config.method === "delete"
    ) {
      store.dispatch({
        type: showNotification,
        payload: { type: "success", message: response?.data?.message },
      });
    }

    return response;
  },
  function (error) {
    store.dispatch({
      type: showNotification,
      payload: { type: "error", message: error?.response?.data?.message },
    });

    return Promise.reject(error);
  }
);

export default API;
