import { Provider } from "react-redux";

import { ThemeProvider } from "@mui/material";
import "./App.css";
import { loadConnectAndInitialize } from "@stripe/connect-js";

import {
  ConnectAccountOnboarding,
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";

import { theme } from "./theme";
import MainRouter from "./Router/MainRouter";
import store from "./stores/store";
import { useState } from "react";

function App() {
  // const test = loadConnectAndInitialize({
  //   // This is your test publishable API key.
  //   publishableKey:
  //     "pk_test_51P2F8yBsrQBJ5JybgXDOAa9PDNRMvbTP8BCOCuoR9Xk8J5TQmskK3op8KlWF8RkdQoqzXTwZCSoz6JiA5cOH6P7t00M2KEPgus",
  //   fetchClientSecret:
  //     "accs_secret__PtpWjNKMpJTzIoxOvuUPwN01Blry04P6QrMuaibB91RKtBf",
  // });

  // const [stripeConnectInstance] = useState(() => {
  //   const fetchClientSecret = async () => {
  //     // Fetch the AccountSession client secret
  //     const response = await fetch("/account_session", { method: "POST" });
  //     if (!response.ok) {
  //       // Handle errors on the client side here
  //       const { error } = await response.json();
  //       console.error("An error occurred: ", error);
  //       document.querySelector("#error").removeAttribute("hidden");
  //       return undefined;
  //     } else {
  //       const { client_secret: clientSecret } = await response.json();
  //       document.querySelector("#error").setAttribute("hidden", "");
  //       return "accs_secret__PtpWjNKMpJTzIoxOvuUPwN01Blry04P6QrMuaibB91RKtBf";
  //     }
  //   };

  //   return loadConnectAndInitialize({
  //     // This is your test publishable API key.
  //     publishableKey:
  //       "pk_test_51P2F8yBsrQBJ5JybgXDOAa9PDNRMvbTP8BCOCuoR9Xk8J5TQmskK3op8KlWF8RkdQoqzXTwZCSoz6JiA5cOH6P7t00M2KEPgus",
  //     fetchClientSecret: fetchClientSecret,
  //   });
  // });
  return (
    <div>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <MainRouter></MainRouter>
          {/* <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
            <ConnectAccountOnboarding />
          </ConnectComponentsProvider> */}
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
