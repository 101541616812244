import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addBlogAPI,
  addBlogImgAPI,
  addEmailTemplateAPI,
  addSMSTemplateAPI,
  addSendSMSAPI,
  addSendWhatsappAPI,
  addSendeMailAPI,
  deleteBlogAPI,
  deleteEmailTemplateAPI,
  deleteSMSTemplateAPI,
  getBlogAPI,
  getEmailTemplateAPI,
  getSMSTemplateAPI,
  getSingleBlogAPI,
  getUsersAPI,
  getUsersCoursePurchasedAPI,
  updateBlogAPI,
  updateEmailTemplateAPI,
  updateSMSTemplateAPI,
  userStateAPI,
} from "../api/MarketingApi";

const initialState = {
  emailTemplates: [],
  emailTemplate: {},

  smsTemplates: [],
  smsTemplate: {},

  users: [],
  user: {},

  blogs: [],
  blog: {},
  blogImg: null,

  userCourse: [],

  loading: false,
  addnUpSmsLoading: null,
  addnUpEmailLoading: null,
  addnUpBlogLoading: null,
};

export const getEmailTemplate = createAsyncThunk(
  "marketing/getEmailTemplate",
  async (rejectWithValue) => {
    try {
      const response = await getEmailTemplateAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addEmailTemplate = createAsyncThunk(
  "marketing/addEmailTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addEmailTemplateAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateEmailTemplate = createAsyncThunk(
  "marketing/updateSyllabus",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateEmailTemplateAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteEmailTemplate = createAsyncThunk(
  "marketing/deleteSyllabus",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteEmailTemplateAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getSMSTemplate = createAsyncThunk(
  "marketing/getSMSTemplate",
  async (rejectWithValue) => {
    try {
      const response = await getSMSTemplateAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSMSTemplate = createAsyncThunk(
  "marketing/addSMSTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSMSTemplateAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateSMSTemplate = createAsyncThunk(
  "marketing/updateSMSTemplate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateSMSTemplateAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteSMSTemplate = createAsyncThunk(
  "marketing/deleteSMSTemplate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteSMSTemplateAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSendeMail = createAsyncThunk(
  "marketing/addSendeMail",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSendeMailAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSendSMS = createAsyncThunk(
  "marketing/addSendSMS",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSendSMSAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addSendWhatsapp = createAsyncThunk(
  "marketing/addSendWhatsapp",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addSendWhatsappAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getUsers = createAsyncThunk(
  "marketing/getUsers",
  async (rejectWithValue) => {
    try {
      const response = await getUsersAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

// getUsersCoursePurchased

export const getUsersCoursePurchased = createAsyncThunk(
  "marketing/getUsersCoursePurchased",
  async (rejectWithValue) => {
    try {
      const response = await getUsersCoursePurchasedAPI();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateUserState = createAsyncThunk(
  "marketing/userstate",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await userStateAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getBlog = createAsyncThunk(
  "blog/getBlog",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getBlogAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const getSingleBlog = createAsyncThunk(
  "blog/getSingleBlog",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await getSingleBlogAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addBlog = createAsyncThunk(
  "marketing/addBlog",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addBlogAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const updateBlog = createAsyncThunk(
  "marketing/updateBlog",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await updateBlogAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteBlog = createAsyncThunk(
  "marketing/deleteBlog",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteBlogAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addBlogImg = createAsyncThunk(
  "marketing/addBlogImg",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addBlogImgAPI(payload);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const marketingSlice = createSlice({
  name: "marketing",
  initialState,

  extraReducers: (builder) => {
    //GET emailTemplates
    builder.addCase(getEmailTemplate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getEmailTemplate.fulfilled, (state, action) => {
      state.emailTemplates = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getEmailTemplate.rejected, (state) => {
      state.loading = false;
    });

    //add emailTemplates
    builder.addCase(addEmailTemplate.pending, (state) => {
      state.addnUpEmailLoading = false;
    });
    builder.addCase(addEmailTemplate.fulfilled, (state, action) => {
      state.emailTemplate = action.payload.response;
      state.emailTemplates
        ? state.emailTemplates.push(action.payload.response)
        : (state.emailTemplates = [action.payload.response]);
      state.addnUpEmailLoading = true;
    });
    builder.addCase(addEmailTemplate.rejected, (state) => {
      state.addnUpEmailLoading = null;
    });

    //update emailTemplates
    builder.addCase(updateEmailTemplate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateEmailTemplate.fulfilled, (state, action) => {
      state.emailTemplate = action.payload.response;
      const { arg } = action.meta;
      if (arg) {
        state.emailTemplates = state.emailTemplates.map((item) =>
          item.emailTemplateId === arg.emailTemplateId
            ? {
                emailTemplateId: arg.emailTemplateId,
                subject: arg.subject,
                emailBody: arg.emailBody,
              }
            : item
        );
      }
      state.loading = true;
    });
    builder.addCase(updateEmailTemplate.rejected, (state) => {
      state.loading = false;
    });

    //delete emailTemplates
    builder.addCase(deleteEmailTemplate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEmailTemplate.fulfilled, (state, action) => {
      state.emailTemplate = action.payload;
      const { arg } = action.meta;
      if (arg) {
        state.emailTemplates = state.emailTemplates.filter(
          (item) => item.emailTemplateId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteEmailTemplate.rejected, (state) => {
      state.loading = false;
    });

    //GET smsTemplates
    builder.addCase(getSMSTemplate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSMSTemplate.fulfilled, (state, action) => {
      state.smsTemplates = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSMSTemplate.rejected, (state) => {
      state.loading = false;
    });

    //add smsTemplates
    builder.addCase(addSMSTemplate.pending, (state) => {
      state.addnUpSmsLoading = false;
    });
    builder.addCase(addSMSTemplate.fulfilled, (state, action) => {
      state.smsTemplate = action.payload?.response;
      state.smsTemplates
        ? state.smsTemplates.push(action.payload.response)
        : (state.smsTemplates = [action.payload.response]);
      state.addnUpSmsLoading = true;
    });
    builder.addCase(addSMSTemplate.rejected, (state) => {
      state.addnUpSmsLoading = null;
    });

    //update smsTemplates
    builder.addCase(updateSMSTemplate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateSMSTemplate.fulfilled, (state, action) => {
      state.smsTemplate = action.payload.response;
      const { arg } = action.meta;
      if (arg) {
        state.smsTemplates = state.smsTemplates.map((item) =>
          item.smsTemplateId === arg.smsTemplateId
            ? {
                smsTemplateId: arg.smsTemplateId,
                message: arg.message,
              }
            : item
        );
      }
      state.loading = true;
    });
    builder.addCase(updateSMSTemplate.rejected, (state) => {
      state.loading = false;
    });

    //delete smsTemplates
    builder.addCase(deleteSMSTemplate.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteSMSTemplate.fulfilled, (state, action) => {
      state.smsTemplate = action.payload;
      const { arg } = action.meta;
      if (arg) {
        state.smsTemplates = state.smsTemplates.filter(
          (item) => item.smsTemplateId !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteSMSTemplate.rejected, (state) => {
      state.loading = false;
    });

    //add addSendeMail
    builder.addCase(addSendeMail.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addSendeMail.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addSendeMail.rejected, (state) => {
      state.loading = false;
    });

    //add addSendSMS
    builder.addCase(addSendSMS.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addSendSMS.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addSendSMS.rejected, (state) => {
      state.loading = false;
    });

    //add addSendWhatsapp
    builder.addCase(addSendWhatsapp.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(addSendWhatsapp.fulfilled, (state, action) => {
      state.loading = true;
    });
    builder.addCase(addSendWhatsapp.rejected, (state) => {
      state.loading = false;
    });

    //GET users
    builder.addCase(getUsers.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
    });

    //GET usersCoursePurchased
    builder.addCase(getUsersCoursePurchased.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getUsersCoursePurchased.fulfilled, (state, action) => {
      state.userCourse = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getUsersCoursePurchased.rejected, (state) => {
      state.loading = false;
    });

    //update smsTemplates
    builder.addCase(updateUserState.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserState.fulfilled, (state, action) => {
      const { arg } = action.meta;
      if (arg) {
        state.users = state.users.map((item) =>
          item.emailId === arg.email
            ? { ...item, isActive: arg.isActive }
            : item
        );
      }

      state.loading = true;
    });
    builder.addCase(updateUserState.rejected, (state) => {
      state.loading = false;
    });

    //GET blog
    builder.addCase(getBlog.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getBlog.fulfilled, (state, action) => {
      state.blogs = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getBlog.rejected, (state) => {
      state.loading = false;
    });

    //GET blog
    builder.addCase(getSingleBlog.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getSingleBlog.fulfilled, (state, action) => {
      state.blog = action.payload?.response;
      state.loading = true;
    });
    builder.addCase(getSingleBlog.rejected, (state) => {
      state.loading = false;
    });

    //add blog
    builder.addCase(addBlog.pending, (state) => {
      state.addnUpBlogLoading = false;
    });
    builder.addCase(addBlog.fulfilled, (state, action) => {
      state.blog = action.payload?.response;
      state.blogs.blog
        ? state.blogs.blog.push(action.payload.response)
        : (state.blogs.blog = [action.payload.response]);
      state.addnUpBlogLoading = true;
    });
    builder.addCase(addBlog.rejected, (state) => {
      state.addnUpBlogLoading = null;
    });

    //update blog
    builder.addCase(updateBlog.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(updateBlog.fulfilled, (state, action) => {
      state.blog = action.payload.response;
      const { arg } = action.meta;
      if (arg) {
        state.blogs = {
          currentPage: state.blogs.currentPage,
          totalItems: state.blogs.totalItems,
          totalPages: state.blogs.totalPages,
          blog: state.blogs.blog.map((item) =>
            Number(item.blogid) === Number(arg.blogid)
              ? {
                  blogUrl: arg.blogUrl,
                  blogdescription: arg.blogdescription
                    ? arg.blogdescription
                    : "",
                  blogid: arg.blogid,
                  blogtitle: arg.blogtitle,
                  featuredImage: arg.featuredImage,
                  thumbnailImage: arg.thumbnailImage,
                  metaDescription: arg.metaDescription,
                  metaKeyWord: arg.metaKeyWord,
                  blogTags: arg.blogTags,
                }
              : item
          ),
        };
      }
      state.loading = true;
    });
    builder.addCase(updateBlog.rejected, (state) => {
      state.loading = false;
    });

    //delete blog
    builder.addCase(deleteBlog.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteBlog.fulfilled, (state, action) => {
      state.blog = action.payload;
      const { arg } = action.meta;
      if (arg) {
        state.blogs.blog = state.blogs.blog.filter(
          (item) => item.blogid !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteBlog.rejected, (state) => {
      state.loading = false;
    });

    //add blog img
    builder.addCase(addBlogImg.pending, (state) => {
      state.blogImg = false;
    });
    builder.addCase(addBlogImg.fulfilled, (state, action) => {
      state.blogImg = action.payload?.response;
      state.addnUpBlogLoading = true;
    });
    builder.addCase(addBlogImg.rejected, (state) => {
      state.blogImg = null;
    });
  },
});

export default marketingSlice.reducer;
