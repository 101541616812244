import API from "./API";

export const surveyAPI = (payload) => {
  return API.post("/user/survey", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getUserAPI = () => {
  return API.get("/user")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
