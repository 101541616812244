import API from "./API";

export const getWebsiteInfoAPI = () => {
  return API.get(`/website`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const updateWebsiteInfoAPI = (payload) => {
  return API.put("/website", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};
