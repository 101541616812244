import API from "./API";

export const getCourseAPI = (params) => {
  return API.get(`/course?page=${params.page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCourseByIdAPI = (id) => {
  return API.get(`/course/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getCourseUserAPI = (params) => {
  return API.get(
    `/course?isCourseActive=${params.isCourseActive}&page=${params.page}&size=4`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSearchCourseAPI = (params) => {
  return API.get(
    `/course?page=${params.page}&search=${params.search}&isCourseActive=2&size=4`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSingleCourseAPI = (courseId) => {
  return API.get(`/course/${courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addCourseAPI = (payload) => {
  return API.post("/course", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCourseAPI = (payload) => {
  return API.put("/course", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteCourseAPI = (id) => {
  return API.delete(`/course/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateCourseStatusAPI = (payload) => {
  return API.put(`/coursestatus/${payload.courseIsActive}/${payload.courseId}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addCourseThumbnailAPI = (payload) => {
  const formData = new FormData();
  formData.append("courseId", payload.courseId);
  formData.append("file", payload.file);

  return API.post("/course/addthumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
