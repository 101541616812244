import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addCourseResourceAPI,
  deleteCourseResourcesAPI,
  getCourseResourcesAPI,
} from "../api/CourseResourceApi";

const initialState = {
  courseResource: {},
  allCourseResource: [],
  loading: false,
  addResLoading: null,
};

export const getCourseResource = createAsyncThunk(
  "course/getallresource",
  async (id, { rejectWithValue }) => {
    try {
      const response = await getCourseResourcesAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const addCourseResource = createAsyncThunk(
  "course/addresource",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await addCourseResourceAPI(payload.data);
      payload.handleCloseRes();
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

export const deleteCourseResource = createAsyncThunk(
  "course/deleteAllresource",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteCourseResourcesAPI(id);
      return response.data;
    } catch (err) {
      return rejectWithValue({
        errorMessage: err?.message,
      });
    }
  }
);

const courseResourceSlice = createSlice({
  name: "courseResource",
  initialState,
  extraReducers: (builder) => {
    //GET get CourseResource
    builder.addCase(getCourseResource.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(getCourseResource.fulfilled, (state, action) => {
      state.allCourseResource = action?.payload?.response;
      state.loading = true;
    });
    builder.addCase(getCourseResource.rejected, (state) => {
      state.loading = false;
    });

    //add add CourseResource
    builder.addCase(addCourseResource.pending, (state) => {
      state.addResLoading = false;
    });
    builder.addCase(addCourseResource.fulfilled, (state, action) => {
      state.courseResource = action?.payload?.response;
      state.allCourseResource
        ? state.allCourseResource.push(action.payload.response)
        : (state.allCourseResource = [action.payload.response]);
      state.addResLoading = true;
    });
    builder.addCase(addCourseResource.rejected, (state) => {
      state.addResLoading = null;
    });

    //add delete CourseResource
    builder.addCase(deleteCourseResource.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCourseResource.fulfilled, (state, action) => {
      const { arg } = action.meta;
      if (arg) {
        state.allCourseResource = state.allCourseResource.filter(
          (item) => item.courseResourceid !== arg
        );
      }
      state.loading = true;
    });
    builder.addCase(deleteCourseResource.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default courseResourceSlice.reducer;
