import React from "react";
import moment from "moment";
import { Box, Typography } from "@mui/material";

import ShadeBar from "../../assets/images/shadeBar.svg";
import BgCerti from "../../assets/images/bgCerti.svg";
import Achiv from "../../assets/images/achiv.svg";
import Logo from "../../assets/Logo/logo.png";
import Director1 from "../../assets/Icon/sing1.png";
import Director2 from "../../assets/Icon/sing2.png";
export default function CertificateDesignThree({
  refi,
  userName = "John Do",
  certificateName = "Certificate Name",
  certificateNumber = "0000-0000",
  certificateDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus nulla tellus, vitae vitae amet fusce. Nam nec aliquet nunc vitae eget mattis. Sem nec ut nisi, et malesuada sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  certificateCreationDate = "2024-01-27T16:33:01.000+00:00",
  certificateValidityDate = "2027-01-27T16:33:01.000+00:00",
}) {
  return (
    <Box>
      <Box
        ref={refi}
        sx={{
          width: "794px",
          height: "560px",
          backgroundImage: `url(${BgCerti})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <Box sx={{ position: "absolute", top: "-11px", left: 0 }}>
          <img src={Achiv} width="100px" alt="achievement" />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <img
            src={Logo}
            width="120px"
            height="120px"
            style={{ marginTop: "14px" }}
            alt="logo"
          />
          <Box sx={{ position: "relative" }}>
            <img src={ShadeBar} width="100%" height="48px" alt="bar" />
            <Typography
              variant="h1"
              sx={{
                fontSize: "28px",
                fontWeight: 700,
                color: "#fff",
                mt: "32px",
                position: "absolute",
                top: "10px",
                left: 0,
                right: 0,
                margin: "0 auto",
              }}
            >
              CERTIFICATE OF COMPLETION
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontSize: "20px",
                fontWeight: 600,
                color: "#3A3A3A",
                mt: "20px",
              }}
            >
              {certificateName}
            </Typography>
          </Box>

          <Typography
            variant="h4"
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              color: "#3A3A3A",
              mt: "10px",
            }}
          >
            This certificate is presented to
          </Typography>

          <Typography
            variant="h2"
            sx={{
              fontSize: "24px",
              fontWeight: 400,
              color: "#3A3A3A",
              mt: "20px",
              textTransform: "capitalize",
            }}
          >
            {userName}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              color: "#3A3A3A",
              mt: "20px",
              mx: "80px",
            }}
          >
            {certificateDescription}
          </Typography>

          <Box
            sx={{
              position: "absolute",
              bottom: "60px",
              width: "650px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",

              mx: "60px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <img
                src={Director1}
                style={{
                  width: "140px",
                  height: "auto",
                }}
                alt="bar"
              />
              <Typography
                variant="body1"
                sx={{ fontSize: "10px", fontWeight: 600 }}
              >
                Signature of a Teacher
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                fontSize: "10px",
                display: "flex",
                flexDirection: "column",
                marginTop: "42px",
                justifyContent: "flex-end",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                {certificateCreationDate
                  ? moment(certificateCreationDate).format("DD-MM-YYYY")
                  : "-"}
              </span>
              <span
                style={{
                  fontWeight: 600,
                }}
              >
                Date
              </span>
            </Typography>
          </Box>
        </Box>
        <Typography
          sx={{
            position: "absolute",
            bottom: "20px",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <span>
            {certificateNumber?.split("-")[0] +
              " - " +
              certificateNumber?.split("-")[1]}
          </span>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "5px",
            }}
          >
            <span>Expiry: </span>

            {certificateValidityDate === certificateCreationDate ? (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                Lifetime
              </span>
            ) : (
              <span
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {certificateValidityDate
                  ? moment(certificateValidityDate).format("DD-MM-YYYY")
                  : "-"}
              </span>
            )}
          </span>
        </Typography>
      </Box>
    </Box>
  );
}
