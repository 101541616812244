import React, { useEffect } from "react";
import moment from "moment";
import WebFont from "webfontloader";
import { Box, Typography } from "@mui/material";

import DesignBox from "../../assets/images/design2-box.png";
import TopLeft from "../../assets/images/left-top-deign2.png";
import BottomRight from "../../assets/images/bottom-right-deisgn2.png";

import Achiv from "../../assets/images/deisgn2-nedal.png";
import Logo from "../../assets/Logo/logo-trans.png";
import Director1 from "../../assets/Icon/sing1.png";
import Director2 from "../../assets/Icon/sing2.png";

export default function CertificateDesignFive({
  refi,
  userName = "John Do",
  certificateName = "Certificate Name",
  certificateNumber = "0000-0000",
  certificateDescription = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus nulla tellus, vitae vitae amet fusce. Nam nec aliquet nunc vitae eget mattis. Sem nec ut nisi, et malesuada sapien. Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  certificateCreationDate = "2024-01-27T16:33:01.000+00:00",
  certificateValidityDate = "2027-01-27T16:33:01.000+00:00",
  courseName = "Course Name",
}) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Aleo", "Lora"],
      },
    });
  }, []);
  return (
    <Box>
      <Box
        ref={refi}
        sx={{
          width: "794px",
          height: "560px",
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
          background: "#fff",
          overflow: "hidden",
        }}
      >
        <img
          src={TopLeft}
          alt="achievement"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "400px",
            objectFit: "contain",
            zIndex: 1,
          }}
        />

        <img
          src={BottomRight}
          alt="achievement"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: "400px",
            objectFit: "contain",
            zIndex: 1,
          }}
        />
        <img
          src={DesignBox}
          alt="achievement"
          style={{
            position: "absolute",
            top: 20,
            left: 20,
            objectFit: "contain",
            width: "750px",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            padding: "60px 80px 0px 80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "470px",
          }}
        >
          <Box
            sx={{
              position: "relative",
              padding: "40px 20px",
              width: "100%",
              height: "100%",
              zIndex: 10,
              borderRadius: "20px",
            }}
          >
            <Box sx={{ position: "absolute", top: "-40px", left: "0" }}>
              <img src={Logo} width="110px" alt="achievement" />
            </Box>
            <Box sx={{ textAlign: "center", mt: "10px" }}>
              <Typography
                variant="h1"
                className="font-Lora"
                sx={{
                  fontSize: "32px",
                  fontWeight: 900,
                  color: "#000",
                  top: "10px",

                  margin: "0 auto",
                }}
              >
                {certificateName.toUpperCase()}
              </Typography>

              <Typography
                variant="h4"
                className="font-Aleo"
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#000",
                  mt: "20px",
                }}
              >
                This certificate is presented to
              </Typography>

              <Typography
                variant="h2"
                className="font-Lora"
                sx={{
                  fontSize: "36px",
                  fontWeight: 800,
                  color: "#0071bc",
                  mt: "20px",
                  textTransform: "capitalize",
                }}
              >
                {userName}
              </Typography>

              <Typography
                variant="h4"
                className="font-Lora"
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "#000",
                  mt: "20px",
                  width: "80%",
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                  paddingTop: "20px",
                }}
              >
                {/* date in this format 17 June 2021 */}
                {`who has successfully completed the ${courseName} `}
                <br></br>
                {`on  ${
                  certificateCreationDate
                    ? moment(certificateCreationDate).format("DD MMMM YYYY")
                    : "-"
                }`}
              </Typography>
              {/* <Typography
                variant="body1"
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: "#3A3A3A",
                  mt: "20px",
                  mx: "80px",
                }}
              >
                {certificateDescription}
              </Typography> */}

              <Box
                sx={{
                  position: "absolute",
                  bottom: "20px",
                  width: "500px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "auto",
                  left: 0,
                  right: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={Director1}
                    style={{
                      width: "140px",
                      height: "auto",
                    }}
                    alt="bar"
                  />
                  <hr
                    style={{
                      width: "100px",
                      height: "1px",
                      background: "#318BC8",
                    }}
                  />
                  <Typography
                    variant="body1"
                    className="font-Aleo"
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#004aad" }}
                  >
                    Director
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={Achiv}
                    style={{
                      width: "80px",
                      height: "auto",
                      objectFit: "contain",
                    }}
                    alt="bar"
                  />
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    <span>Expiry: </span>

                    {certificateValidityDate === certificateCreationDate ? (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        Lifetime
                      </span>
                    ) : (
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {certificateValidityDate
                          ? moment(certificateValidityDate).format("DD-MM-YYYY")
                          : "-"}
                      </span>
                    )}
                  </span>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={Director2}
                    style={{
                      width: "140px",
                      height: "auto",
                    }}
                    alt="bar"
                  />
                  <hr
                    style={{
                      width: "100px",
                      height: "1px",
                      background: "#318BC8",
                    }}
                  />
                  <Typography
                    variant="body1"
                    className="font-Aleo"
                    sx={{ fontSize: "14px", fontWeight: 500, color: "#004aad" }}
                  >
                    Director
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
