import API from "./API";

export const getEmailTemplateAPI = () => {
  return API.get("/emailtemplate")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addEmailTemplateAPI = (payload) => {
  return API.post("/emailtemplate", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateEmailTemplateAPI = (payload) => {
  return API.put("/emailtemplate", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteEmailTemplateAPI = (id) => {
  return API.delete(`/emailtemplate/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSMSTemplateAPI = () => {
  return API.get("/smstemplate")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addSMSTemplateAPI = (payload) => {
  return API.post("/smstemplate", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateSMSTemplateAPI = (payload) => {
  return API.put("/smstemplate", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteSMSTemplateAPI = (id) => {
  return API.delete(`/smstemplate/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addSendeMailAPI = (payload) => {
  return API.post("/sendemail", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addSendSMSAPI = (payload) => {
  return API.post("/sendsms", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addSendWhatsappAPI = (payload) => {
  return API.post("/sendwhatsapp", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUsersAPI = () => {
  return API.get("/users")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getUsersCoursePurchasedAPI = (id) => {
  return API.get(`/users?hasCourse=1`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const userStateAPI = (payload) => {
  return API.post(
    `/userstate?isActive=${payload.isActive}&username=${payload.email}`
  )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getBlogAPI = (params) => {
  return API.get(`/blog?page=${params.page}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const getSingleBlogAPI = (id) => {
  return API.get(`/blog/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addBlogAPI = (payload) => {
  return API.post("/blog", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const updateBlogAPI = (payload) => {
  return API.put("/blog", payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const deleteBlogAPI = (id) => {
  return API.delete(`/blog/${id}`)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};

export const addBlogImgAPI = (payload) => {
  const formData = new FormData();
  formData.append("file", payload.file);

  return API.post("/blog/addthumbnail", formData, {
    headers: {
      "Content-Type":
        "multipart/form-data; boundary=----WebKitFormBoundaryTtTi35D4MiWOz5uq",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
