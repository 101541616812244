import API from "./API";

export const startTestAPI = (payload) => {
  return API.post("/starttest", payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const stopTestAPI = (payload) => {
  return API.put("/stoptest", payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const downloadCertficateAPI = (payload) => {
  return API.post(`/certificate/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getResultAPI = (payload) => {
  return API.get(`/result/${payload}`)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

export const getCertificateAPI = () => {
  return API.get("/certificate")
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
};
