import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  root: {
    flexGrow: 1,
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: "16px",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.color === "primary" && {
            backgroundColor: "#DB2BBB",
            ":hover": {
              backgroundColor: "#DB2BBB29",
              color: "#403e3b",
            },
          }),
          ...(ownerState.color === "secondary" && {
            color: "#3A3A3A",
            backgroundColor: "#fffff",
            fontFamily: ["Outfit", "sans-serif"].join(","),
            ":hover": {
              backgroundColor: "#f5f5fc",
            },
          }),
        }),
        text: {
          fontFamily: ["Outfit", "sans-serif"].join(","),
        },
      },
    },

    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: "#063755",
          color: "white",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#DB2BBB",
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {},
        rounded: {},
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          color: 'red !important',
          fontFamily: ["Outfit", "sans-serif"].join(","),
          fontSize: '16px'
        }
      },
    }
    // MuiCheckbox:{
    //   styleOverrides:{
    //     root:{

    //     }
    //   }
    // }
  },

  palette: {
    primary: {
      main: "#f5f5fc",
      contrastText: "white",
    },
    secondary: {
      main: "#e6cae0",
      contrastText: "#f5f5fc",
    },
  }
});
